import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import motorVehicleAPIService from "./vehicleAPIService";


const initialState = {
    motorvehicles: [],
    motorvehicle: {},
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ''
};


//create motor vehicle
export const createMotorVehicle = createAsyncThunk('motorvehicle/create',
    async (motorVehicleData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await motorVehicleAPIService.createMotorVehicle(motorVehicleData, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//update motor vehicle
export const updateMotorVehicle = createAsyncThunk('motorvehicle/update',
    async ({ motorVehicleData, barcode }, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await motorVehicleAPIService.updateMotorVehicle(motorVehicleData, barcode, token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
)

//get motor vehicles register
export const getMotorVehicles = createAsyncThunk('motorvehicles/getAll',
    async (_, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.token;
            return await motorVehicleAPIService.getMotorVehicles(token);
        } catch (error) {
            const message =
                error.message ||
                error.toString() ||
                error.response?.data?.name[0];

            // return thunkAPI.rejectWithValue({ error: message });
            throw new Error(message);
        }
    }
);

export const motorvehicleSlice = createSlice({
    name: 'motorvehicle',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            //create motor vehicle
            .addCase(createMotorVehicle.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(createMotorVehicle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.motorvehicle = action.payload;
                state.message = 'Motor vehicle created successfully';
            })
            .addCase(createMotorVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //update motor vehicle
            .addCase(updateMotorVehicle.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
                state.message = '';
            })
            .addCase(updateMotorVehicle.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.motorvehicle = action.payload;
                state.message = 'Motor vehicle updated successfully';
            })
            .addCase(updateMotorVehicle.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
            //get all motor vehicles
            .addCase(getMotorVehicles.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMotorVehicles.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.motorvehicles = action.payload.results;
                state.message = "";
            })
            .addCase(getMotorVehicles.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.error.message;
            })
    },
});

export const { reset } = motorvehicleSlice.actions;
export default motorvehicleSlice.reducer;