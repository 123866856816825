import React from 'react';
import { tokens } from "../../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Pages for each register tab

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MotorVehicleDetailView = (rowData) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [value, setValue] = React.useState(0);
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const data = rowData.rowData;

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    width="100%"
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons={isNonMobile ? 'auto' : 'on'}
                    allowScrollButtonsMobile
                    aria-label='Transport Equipment Tabs'
                    TabIndicatorProps={{ style: { backgroundColor: colors.greenAccent[500] } }}
                    textColor='inherit'
                >
                    <Tab label='Details' {...a11yProps(0)} />
                    <Tab label='Accounting' {...a11yProps(1)} />
                    <Tab label='Maintenance' {...a11yProps(2)} />
                    <Tab label='History' {...a11yProps(3)} />
                    <Tab label='Documents' {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel width="100%" value={value} index={0}>
                <Box>
                    <Typography variant='h4'>
                        {data.asset_make} {data.asset_type}
                    </Typography>
                    <Box>
                        <img src={data.asset_image} alt={data.barcode} style={{ width: "100%", height: "auto", borderRadius: "10px", m: "1.5rem" }} />
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            <b>Barcode:</b> {data.barcode}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Serial No:</b> {data.serial_no}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Make:</b> {data.asset_make}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Model:</b> {data.asset_model}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Chasis Number:</b> {data.chasis_no}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Engine Number:</b> {data.asset_model}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Engine Type:</b> {data.engine_type}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Location:</b> {data.location}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>GPS Coordinates:</b> {data.gps_coordinates}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Status:</b> {data.status}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Condition:</b> {data.condition}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            <b>Purchase Price:</b> {data.cost}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Current Value:</b> {data.cost}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Depreciation:</b> {data.depreciation}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Date Purchased:</b> {data.purchase_date}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            <b>Warranty Expiry:</b> {data.warranty_expiry}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Maintenance Type: {data.maintenance_type}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Description: {data.maintenance_description}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Vendor: {data.maintenance_vendor}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Status: {data.maintenance_status}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Period: {data.maintenance_period}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Frequency: {data.maintenance_frequency}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Schedule: {data.maintenance_schedule}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Cost: {data.maintenance_cost}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Maintenance Date: {data.maintenance_date}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Last Updated: {data.last_updated}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Updated By: {data.updated_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                            Created By: {data.created_by}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Box>
                    <Box>
                        <Typography
                            variant='h5'
                            fontWeight={350}
                            sx={{ color: colors.grey[100], lineHeight: "1.5rem" }}
                        >
                            Document Name: {data.document_name}
                            <hr style={{ height: "0.5px", borderColor: colors.grey[400] }} />
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
    );
};

export default MotorVehicleDetailView;