import React, { useState } from "react";
import { tokens } from "../../../theme";
import { Box, Typography, useTheme } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TopAppBar from "../../global/TopAppBar";
// Pages for each register tab
import AssetsPage from "./assets_mrt";
import FurnituresPage from "../furniture/furniture_mrt";
import IctEquipmentPage from "../ict-equipment/ictequipment_mrt";
import IntangibleAssetsPage from "../intangible/intangibleassets_mrt";
import OfficeEquipmentPage from "../office-equipment/officeequipment_mrt";
import BuildingsPage from "../buildings/buildings_mrt";
import LandPage from "../land/land_mrt";
import MotorVehiclePage from "../motor-vehicles/motorvehicles_mrt";
// Icons for each register tab
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RegisterPage = () => {
  const [setIsSidebar] = useState(true);
  const [value, setValue] = React.useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="app">
      <main className="content">
        <TopAppBar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Box sx={{ width: '100%' }}>
            <Box mb="0.1rem">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="Locations Tabs"
                TabIndicatorProps={{ style: { background: colors.blueAccent[600] } }}
                textColor="inherit"
              >
                <Tab icon={<ReceiptLongOutlinedIcon />} iconPosition="start" label="Assets Register" {...a11yProps(0)} />
                <Tab icon={<ChairOutlinedIcon />} iconPosition="start" label="Furniture & Fittings" {...a11yProps(1)} />
                <Tab icon={<DevicesOtherOutlinedIcon />} iconPosition="start" label="Computers & ICT Equipment" {...a11yProps(2)} />
                <Tab icon={<PunchClockOutlinedIcon />} iconPosition="start" label="Office Equipment" {...a11yProps(3)} />
                <Tab icon={<CommuteOutlinedIcon />} iconPosition="start" label="Motor Vehicles & Bikes" {...a11yProps(4)} />
                <Tab icon={<AlbumOutlinedIcon />} iconPosition="start" label="Intangible Assets" {...a11yProps(5)} />
                <Tab icon={<FactoryOutlinedIcon />} iconPosition="start" label="Buildings" {...a11yProps(6)} />
                <Tab icon={<FactoryOutlinedIcon />} iconPosition="start" label="Land" {...a11yProps(7)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <AssetsPage />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <FurnituresPage />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <IctEquipmentPage />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <OfficeEquipmentPage />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <MotorVehiclePage />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <IntangibleAssetsPage />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <BuildingsPage />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <LandPage />
            </TabPanel>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default RegisterPage;
