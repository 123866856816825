import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Formik } from 'formik';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, IconButton, MenuItem, TextField } from "@mui/material";
import FormikDatePicker from "components/FormikDatePicker";
import Dropzone from "react-dropzone";
// Categorization imports
import { getCategories } from "../../../features/entities/categories/categorySlice";
import { getCategorySubCategories } from "features/entities/subcategories/subcategorySlice";
import { getSubCategoryAssetTypes } from "features/entities/types/typeSlice";
import { getTypeAssetMakes } from "features/entities/makes/makeSlice";
import { getMakeAssetModels } from "features/entities/models/modelSlice";
// location imports
import { getRegions } from "../../../features/entities/regions/regionSlice";
import { getRegionBranches } from "../../../features/entities/branches/branchSlice";
import { getBranchRooms } from "../../../features/entities/rooms/roomSlice";
// create transport equipment slice import
import { createMotorVehicle } from "../../../features/register/motor-vehicles/vehicleSlice";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const initialValues = {
    barcode: "",
    cost: "",
    description: "",
    category_class: "",
    category_sub_class: "",
    asset_type: "",
    asset_make: "",
    asset_model: "",
    chasis_no: "",
    engine_number: "",
    engine_type: "",
    year_of_manufacture: "",
    gen_capacity: "",
    spare_tyre: "",
    wheel_spanner: "",
    jack: "",
    first_aid_kit: "",
    fire_extinguisher: "",
    warning_triangle: "",
    region: "",
    branch: "",
    room: "",
    location: "",
    gps_coordinates: "",
    condition: "",
    status: "",
    funder: "",
    remarks: "",
    asset_image: null, // Modified to store the image file
};

const motorVehicleSchema = yup.object().shape({
    barcode: yup.string().required("Barcode is required"),
    cost: yup.string(),
    description: yup.string(),
    category_class: yup.string().required("Category Class is required"),
    category_sub_class: yup.string().required("Category Sub Class is required"),
    asset_type: yup.string().required("Asset Type is required"),
    asset_make: yup.string().required("Asset make is required"),
    asset_model: yup.string().required("Asset model is required"),
    chasis_no: yup.string(),
    engine_number: yup.string(),
    engine_type: yup.string(),
    year_of_manufacture: yup.string(),
    gen_capacity: yup.string(),
    spare_tyre: yup.string(),
    wheel_spanner: yup.string(),
    jack: yup.string(),
    first_aid_kit: yup.string(),
    fire_extinguisher: yup.string(),
    warning_triangle: yup.string(),
    region: yup.string().required("Region is required"),
    branch: yup.string().required("Branch is required"),
    room: yup.string(),
    location: yup.string(),
    gps_coordinates: yup.string(),
    condition: yup.string().required("Condition is required"),
    status: yup.string().required("Status is required"),
    remarks: yup.string(),
    image: yup.mixed(), // Modified to store the image file
});

const AddMotorVehicleform = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    // alert message
    const { isError, isSuccess, message } = useSelector((state) => state.motorvehicles);
    // location
    const { regions } = useSelector((state) => state.regions);
    const { branches: { regionbranches } } = useSelector((state) => state);
    // categorization
    const { categories } = useSelector((state) => state.categories);
    // select category to use from the list of categories
    const categoryOptions = categories.filter((category) => category.name === "Motor Vehicles and Bikes");
    const { subcategories: { categorysubcategories } } = useSelector((state) => state);
    const { assettypes: { subcategoryassettypes } } = useSelector((state) => state);
    const { assetmakes: { typeassetmakes } } = useSelector((state) => state);
    const { assetmodels: { makeassetmodels } } = useSelector((state) => state);

    // Asset condition options
    const conditionOptions = [{ pkid: "NEW", name: "New" }, { pkid: "BAD", name: "Bad" }, { pkid: "GOOD", name: "Good" }, { pkid: "BROKEN", name: "Broken" }];

    // Asset status options
    const statusOptions = [{ pkid: "IN_USE", name: "In Use" }, { pkid: "NOT_IN_USE", name: "Not In Use" }, { pkid: "OBSOLETE", name: "Obsolete" }];

    // Asset Funder options
    const funderOptions = [{ pkid: "ERA", name: "ERA" }, { pkid: "UMEME", name: "Umeme" }, { pkid: "UEDCL", name: "UEDCL" }];

    // Engine type options
    const engineTypeOptions = [{ pkid: "PETROL", name: "Petrol" }, { pkid: "DIESEL", name: "Diesel" }, { pkid: "ELECTRIC", name: "Electric" }, { pkid: "HYBRID", name: "Hybrid" }];

    // Generator Capacity options in KVA
    const generatorCapacityOptions = [{ pkid: "KVA_12.5", name: "12.5 KVA" }, { pkid: "KVA_15_5", name: "15.5 KVA" }, { pkid: "KVA_16.5", name: "16.5 KVA" }, { pkid: "KVA_17.5", name: "17.5 KVA" }, { pkid: "KVA_20.0", name: "20.0 KVA" }, { pkid: "KVA_22.4", name: "22.4 KVA" }, { pkid: "KVA_30.0", name: "30.0 KVA" }, { pkid: "KVA_100.0", name: "100.0 KVA" }, { pkid: "KVA_110.0", name: "110.0 KVA" }, { pkid: "KVA_150.0", name: "150.0 KVA" }]

    // Car Items check
    const carItemsCheck = [{ pkid: "YES", name: "Yes" }, { pkid: "NO", name: "No" }]

    // location and users data fetch
    useEffect(() => {
        dispatch(getRegions());
    }, [dispatch]);

    // categorization data fetch
    useEffect(() => {
        dispatch(getCategories());
    }, [dispatch]);

    // handle categorization cascading
    const handleCategoryChange = (event, formik) => {
        const category_class = event.target.value;
        formik.setFieldValue("category_class", category_class);
        formik.setFieldValue("category_sub_class", ""); // Reset category_sub_class value
        dispatch(getCategorySubCategories(category_class));
    };

    const handleSubCategoryChange = (event, formik) => {
        const category_sub_class = event.target.value;
        formik.setFieldValue("category_sub_class", category_sub_class);
        formik.setFieldValue("asset_type", ""); // Reset asset_type value
        dispatch(getSubCategoryAssetTypes(category_sub_class));
    };

    const handleAssetTypeChange = (event, formik) => {
        const asset_type = event.target.value;
        formik.setFieldValue("asset_type", asset_type);
        formik.setFieldValue("asset_make", ""); // Reset asset_make value
        dispatch(getTypeAssetMakes(asset_type));
    };

    const handleAssetMakeChange = (event, formik) => {
        const asset_make = event.target.value;
        formik.setFieldValue("asset_make", asset_make);
        formik.setFieldValue("asset_model", ""); // Reset asset_model value
        dispatch(getMakeAssetModels(asset_make));
    };

    // handle location cascading
    const handleRegionChange = (event, formik) => {
        const region = event.target.value;
        formik.setFieldValue("region", region);
        formik.setFieldValue("branch", ""); // Reset branch value
        dispatch(getRegionBranches(region));
    };

    const handleBranchChange = (event, formik) => {
        const branch = event.target.value;
        formik.setFieldValue("branch", branch);
        formik.setFieldValue("room", ""); // Reset room value
        dispatch(getBranchRooms(branch));
    };

    // Get GPS coordinates
    function getCoordinates() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }

    // Set GPS coordinates to GPS field
    async function handleGetCoordinates(formik) {
        try {
            const position = await getCoordinates();
            const { latitude, longitude } = position.coords;
            formik.setFieldValue('gps_coordinates', `${latitude}, ${longitude}`);
        } catch (error) {
            console.error(error);
        }
    }

    // const handleFileDrop = (acceptedFiles, formik) => {
    //     formik.setFieldValue("image", acceptedFiles[0]);
    // };

    const handleImageDrop = (acceptedFiles, formik) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            formik.setFieldValue("asset_image", reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        // send new form data to backend
        console.log(values);
        await dispatch(createMotorVehicle(values));
        onSubmitProps.resetForm(); // Clear the form
        setOpen(true); // Re-open the alert
    };

    return (
        <Box m="10px">
            <Box mb="1rem">
                <Collapse in={open}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        {isError && isError.length > 0 ? (
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                        {isSuccess && isSuccess.length > 0 ? (
                            <Alert
                                severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{isSuccess}</strong>
                            </Alert>
                        ) : (null)}
                        {message && message.length > 0 ? (
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => { setOpen(false); }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <strong>{message}</strong>
                            </Alert>
                        ) : (null)}
                    </Stack>
                </Collapse>
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={motorVehicleSchema}
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                hidden
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.location}
                                name="location"
                                error={formik.touched.location && !!formik.errors.location}
                                helperText={formik.touched.location && formik.errors.location}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="hidden"
                                label=""
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                error={formik.touched.description && !!formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Plate Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.barcode}
                                name="barcode"
                                error={formik.touched.barcode && !!formik.errors.barcode}
                                helperText={formik.touched.barcode && formik.errors.barcode}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                label="Purchase Price"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.cost}
                                name="cost"
                                error={formik.touched.cost && !!formik.errors.cost}
                                helperText={formik.touched.cost && formik.errors.cost}
                                sx={{ gridColumn: "span 2" }}
                            />

                            {/* Categorization */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Category"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleCategoryChange(event, formik)}
                                value={formik.values.category_class}
                                name="category_class"
                                error={formik.touched.category_class && !!formik.errors.category_class}
                                helperText={formik.touched.category_class && formik.errors.category_class}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {categoryOptions.map((category) => (
                                    <MenuItem key={category.pkid} value={category.pkid}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="SubCategory"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleSubCategoryChange(event, formik)}
                                value={formik.values.category_sub_class}
                                name="category_sub_class"
                                error={formik.touched.category_sub_class && !!formik.errors.category_sub_class}
                                helperText={formik.touched.category_sub_class && formik.errors.category_sub_class}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {categorysubcategories.map((categorysubcategory) => (
                                    <MenuItem key={categorysubcategory.pkid} value={categorysubcategory.pkid}>
                                        {categorysubcategory.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Type"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleAssetTypeChange(event, formik)}
                                value={formik.values.asset_type}
                                name="asset_type"
                                error={formik.touched.asset_type && !!formik.errors.asset_type}
                                helperText={formik.touched.asset_type && formik.errors.asset_type}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {subcategoryassettypes.map((subcategoryassettype) => (
                                    <MenuItem key={subcategoryassettype.pkid} value={subcategoryassettype.pkid}>
                                        {subcategoryassettype.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Make"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleAssetMakeChange(event, formik)}
                                value={formik.values.asset_make}
                                name="asset_make"
                                error={formik.touched.asset_make && !!formik.errors.asset_make}
                                helperText={formik.touched.asset_make && formik.errors.asset_make}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {typeassetmakes.map((typeassetmake) => (
                                    <MenuItem key={typeassetmake.pkid} value={typeassetmake.pkid}>
                                        {typeassetmake.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Asset Model"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.asset_model}
                                name="asset_model"
                                error={formik.touched.asset_model && !!formik.errors.asset_model}
                                helperText={formik.touched.asset_model && formik.errors.asset_model}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {makeassetmodels.map((makeassetmodel) => (
                                    <MenuItem key={makeassetmodel.pkid} value={makeassetmodel.pkid}>
                                        {makeassetmodel.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Car details */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Chasis Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.chasis_no}
                                name="chasis_no"
                                error={formik.touched.chasis_no && !!formik.errors.chasis_no}
                                helperText={formik.touched.chasis_no && formik.errors.chasis_no}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Engine Number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.engine_number}
                                name="engine_number"
                                error={formik.touched.engine_number && !!formik.errors.engine_number}
                                helperText={formik.touched.engine_number && formik.errors.engine_number}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Engine Type"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.engine_type}
                                name="engine_type"
                                error={formik.touched.engine_type && !!formik.errors.engine_type}
                                helperText={formik.touched.engine_type && formik.errors.engine_type}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {engineTypeOptions.map((engine_type) => (
                                    <MenuItem key={engine_type.pkid} value={engine_type.pkid}>
                                        {engine_type.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormikDatePicker
                                fullWidth
                                variant="outlined"
                                label="Manufacturing Date"
                                value={formik.values.year_of_manufacture}
                                name="year_of_manufacture"
                                onChange={(value) => formik.setFieldValue('year_of_manufacture', value)}
                                error={formik.touched.year_of_manufacture && !!formik.errors.year_of_manufacture}
                                helperText={formik.touched.year_of_manufacture && formik.errors.year_of_manufacture}
                                sx={{ gridColumn: "span 2" }}
                            />

                            {/* Generator details */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Generator Capacity"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.gen_capacity}
                                name="gen_capacity"
                                error={formik.touched.gen_capacity && !!formik.errors.gen_capacity}
                                helperText={formik.touched.gen_capacity && formik.errors.gen_capacity}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {generatorCapacityOptions.map((capacity) => (
                                    <MenuItem key={capacity.pkid} value={capacity.pkid}>
                                        {capacity.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Car Items */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Spare Tyre"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.spare_tyre}
                                name="spare_tyre"
                                error={formik.touched.spare_tyre && !!formik.errors.spare_tyre}
                                helperText={formik.touched.spare_tyre && formik.errors.spare_tyre}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Wheel Spanner"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.wheel_spanner}
                                name="wheel_spanner"
                                error={formik.touched.wheel_spanner && !!formik.errors.wheel_spanner}
                                helperText={formik.touched.wheel_spanner && formik.errors.wheel_spanner}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Jack"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.jack}
                                name="jack"
                                error={formik.touched.jack && !!formik.errors.jack}
                                helperText={formik.touched.jack && formik.errors.jack}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="First Aid Kit"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.first_aid_kit}
                                name="first_aid_kit"
                                error={formik.touched.first_aid_kit && !!formik.errors.first_aid_kit}
                                helperText={formik.touched.first_aid_kit && formik.errors.first_aid_kit}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Fire Extinguisher"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.fire_extinguisher}
                                name="fire_extinguisher"
                                error={formik.touched.fire_extinguisher && !!formik.errors.fire_extinguisher}
                                helperText={formik.touched.fire_extinguisher && formik.errors.fire_extinguisher}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Warning Triangle"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.warning_triangle}
                                name="warning_triangle"
                                error={formik.touched.warning_triangle && !!formik.errors.warning_triangle}
                                helperText={formik.touched.warning_triangle && formik.errors.warning_triangle}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {carItemsCheck.map((carItem) => (
                                    <MenuItem key={carItem.pkid} value={carItem.pkid}>
                                        {carItem.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* Location */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Region"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleRegionChange(event, formik)}
                                value={formik.values.region}
                                name="region"
                                error={formik.touched.region && !!formik.errors.region}
                                helperText={formik.touched.region && formik.errors.region}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {regions.map((region) => (
                                    <MenuItem key={region.pkid} value={region.pkid}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Branch"
                                onBlur={formik.handleBlur}
                                onChange={(event) => handleBranchChange(event, formik)}
                                value={formik.values.branch}
                                name="branch"
                                error={formik.touched.branch && !!formik.errors.branch}
                                helperText={formik.touched.branch && formik.errors.branch}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {regionbranches.map((regionbranch) => (
                                    <MenuItem key={regionbranch.pkid} value={regionbranch.pkid}>
                                        {regionbranch.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="GPS Coordinates"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.gps_coordinates}
                                name="gps_coordinates"
                                error={formik.touched.gps_coordinates && !!formik.errors.gps_coordinates}
                                helperText={formik.touched.gps_coordinates && formik.errors.gps_coordinates}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <Box display="flex" justifyContent="center" mt="5px">
                                <Button fullWidth color="secondary" variant="contained" onClick={() => handleGetCoordinates(formik)}>
                                    Get GPS Coordinates
                                </Button>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Condition"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.condition}
                                name="condition"
                                error={formik.touched.condition && !!formik.errors.condition}
                                helperText={formik.touched.condition && formik.errors.condition}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {conditionOptions.map((condition) => (
                                    <MenuItem key={condition.pkid} value={condition.pkid}>
                                        {condition.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Status"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.status}
                                name="status"
                                error={formik.touched.status && !!formik.errors.status}
                                helperText={formik.touched.status && formik.errors.status}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {statusOptions.map((status) => (
                                    <MenuItem key={status.pkid} value={status.pkid}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                select
                                label="Funder"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.funder}
                                name="funder"
                                error={formik.touched.funder && !!formik.errors.funder}
                                helperText={formik.touched.funder && formik.errors.funder}
                                sx={{ gridColumn: "span 2" }}
                            >
                                {funderOptions.map((funder) => (
                                    <MenuItem key={funder.pkid} value={funder.pkid}>
                                        {funder.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="text"
                                label="Remark"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.remark}
                                name="remark"
                                error={formik.touched.remark && !!formik.errors.remark}
                                helperText={formik.touched.remark && formik.errors.remark}
                                sx={{ gridColumn: "span 4" }}
                            />

                            {/* Image and file uploads */}
                            <Dropzone onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles, formik)}>
                                {({ getRootProps, getInputProps }) => (
                                    <Box
                                        {...getRootProps()}
                                        sx={{
                                            border: "2px dashed grey",
                                            borderRadius: "5px",
                                            padding: "20px",
                                            gridColumn: "span 4",
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <Box textAlign="center">
                                            {formik.values.asset_image ? (
                                                <div>
                                                    <strong>Selected File:</strong> {formik.values.asset_image.name}
                                                </div>
                                            ) : (
                                                <div>Drag and drop an image file here, or click to select a file</div>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Dropzone>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Button fullWidth type="submit" color="secondary" variant="contained">
                                Create Asset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default AddMotorVehicleform;
