import axios from "axios";

const CREATE_MOTOR_VEHICLE_URL = "/api/v1/register/motor-vehicle-asset-create/";
const UPDATE_MOTOR_VEHICLE_URL = "/api/v1/register/motor-vehicle-asset-update/barcode:barcode/";
const GET_MOTOR_VEHICLES_URL = "/api/v1/register/motor-vehicles-register/";

//get Motor Vehicles
const getMotorVehicles = async (token) => {
    const response = await axios.get(GET_MOTOR_VEHICLES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create motor vehicle
const createMotorVehicle = async (motorVehicleData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_MOTOR_VEHICLE_URL, motorVehicleData, config);
    return response.data;
};


//update motor vehicle
const updateMotorVehicle = async (motorVehicleData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_MOTOR_VEHICLE_URL.replace("barcode", barcode), motorVehicleData, config);
    return response.data;
};

const motorVehicleAPIService = { createMotorVehicle, updateMotorVehicle, getMotorVehicles };

export default motorVehicleAPIService;