import axios from "axios";

const CREATE_LAND_URL = "/api/v1/register/land-asset-create/";
const UPDATE_LAND_URL = "/api/v1/register/land-asset-update/barcode:barcode/";
const GET_LAND_URL = "/api/v1/register/land-register/";

//get land
const getLand = async (token) => {
    const response = await axios.get(GET_LAND_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data
};


//create specialized Land
const createLand = async (landData, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const response = await axios.post(CREATE_LAND_URL, landData, config);
    return response.data;
};


//update Land
const updateLand = async (landData, barcode, token) => {
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };
    const response = await axios.put(UPDATE_LAND_URL.replace("barcode", barcode), landData, config);
    return response.data;
};

const landAPIService = { createLand, updateLand, getLand };

export default landAPIService;