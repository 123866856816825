import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { getAssets } from "../features/register/assets/assetSlice";

const AssetsByLocationBarData = () => {
  const dispatch = useDispatch();
  const { assets } = useSelector((state) => state.assets);

  useEffect(() => {
    dispatch(getAssets())
  }, [dispatch]);

  // Function to generate a color for a category
  const generateColor = (category) => {
    if (!category) {
      return 'hsl(0, 70%, 50%)';  // Default color
    }
    let hash = 0;
    for (let i = 0; i < category.length; i++) {
      hash = category.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const generateBarData = (assets, categories) => {
    // Initialize an empty object to hold the data
    const data = {};

    // Iterate over the assets
    assets.forEach(asset => {
      // Extract the region from the location
      const region = asset.location.split(' - ')[1];

      // If the region does not exist in the data object, add it
      if (!data[region]) {
        data[region] = { region: region };
        categories.forEach(category => {
          data[region][category] = 0;
          data[region][`${category}Color`] = generateColor(category);
        });
      }

      // If the asset's category is in the categories list, increment the count
      if (categories.includes(asset.category_class)) {
        data[region][asset.category_class]++;
      }
    });

    // Convert the data object to an array
    return Object.values(data);
  };

  // Extract categories from assets data
  const categories = [...new Set(assets.map(asset => asset.category_class))];

  const barData = generateBarData(assets, categories);

  return { barData, categories };
};

export default AssetsByLocationBarData;