import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, DialogContent, DialogTitle, IconButton, Tooltip, } from "@mui/material";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { getLand } from "../../../features/register/land/landSlice";

import Stack from '@mui/material/Stack';

import PopupDialog from "../../../components/PopupDialog";
import AddLandForm from "./addform";
import EditLandForm from "./editform";
import LandDetailView from "./detailview";

// for material react table
import { MaterialReactTable, useMaterialReactTable, } from "material-react-table";
// import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
// import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { mkConfig, generateCsv, download } from 'export-to-csv';
// import { jsPDF } from 'jspdf';
// import autoTable from 'jspdf-autotable';
// import { format } from 'date-fns';

const LandPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // data for material react table
    const { lands } = useSelector((state) => state.lands);

    // for material react table
    const [isLoading, setIsLoading] = useState(false);
    const [isError] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 });

    // refetch data
    const refetch = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    };

    // table rows export
    function handleExportRows(rows) {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    }

    // table data export csv
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(lands);
        download(csvConfig)(csv);
    };

    // csv format config
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                dispatch(getLand());
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    // Create a new array with the formatted data
    // const formattedData = Land.map(item => ({
    //     ...item,
    //     purchase_date: item.purchase_date ? format(new Date(item.purchase_date), 'dd/MM/yyyy HH:mm') : '',
    //     deployment_date: item.deployment_date ? format(new Date(item.deployment_date), 'dd/MM/yyyy HH:mm') : '',
    //     cost: item.cost ? parseFloat(item.cost).toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
    // }));

    const columns = useMemo(() => {
        if (lands.length > 0) {
            const keys = Object.keys(lands[0]);
            return keys.reduce((obj, key) => {
                obj[key] = key;
                return obj;
            }, {});
        } else {
            return {};
        }
    }, [lands]);

    // Update to getting columns from the backend data
    const columnsToDisplay = useMemo(() => {
        const columnsToExclude = ['pkid', 'slug', 'id'];
        return lands.length
            ? Object.keys(lands[0])
                .filter(columnId => !columnsToExclude.includes(columnId))
                .map((columnId) => ({
                    header: columns[columnId]?.replace(/_/g, " ") ?? columnId,
                    accessorKey: columnId,
                    id: columnId,
                }))
            : [];
    }, [lands, columns]);

    // table data export pdf
    // const handlePdfExport = (rows) => {
    //     const doc = new jsPDF();
    //     const tableData = rows.map((row) => Object.values(row.original));
    //     const tableHeaders = columnsToDisplay.map((c) => c.header);

    //     autoTable(doc, {
    //         head: [tableHeaders],
    //         body: tableData,
    //     });

    //     doc.save('Land-reg.pdf');
    // };


    const table = useMaterialReactTable({
        columns: columnsToDisplay,
        data: lands,
        //Sticky header and footer
        enableStickyHeader: true,
        //Rows
        enableRowSelection: true,
        enableRowActions: true,
        enableRowNumbers: true,
        rowNumberDisplayMode: 'static',
        enableRowPinning: true,
        rowPinningDisplayMode: 'top-and-bottom',
        // using this for detail view
        createDisplayMode: 'modal',
        //Editing
        editDisplayMode: 'modal',
        enableEditing: true,
        //Filtering
        enableColumnPinning: true,
        enableColumnFilters: true,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        initialState: {
            columnPinning: {
                left: ['mrt-row-select'],
                right: ['mrt-row-actions'],
            },
            density: 'compact'
        },
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        //Pagination
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
        },
        getRowId: (row) => row.id,
        // give loading spinner somewhere to go while loading
        muiTableBodyProps: {
            children: isLoading ? (
                <tr style={{ height: '200px' }}>
                    <td />
                </tr>
            ) : undefined,
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        state: {
            columnFilters,
            globalFilter,
            sorting,
            pagination
        },
        //optionally customize modal content
        renderEditRowDialogContent: ({ table, row }) => (
            <>
                <DialogTitle variant="h4">Edit Land</DialogTitle>
                <IconButton aria-label="close" onClick={() => table.setEditingRow(null)} sx={{ position: 'absolute', right: 8, top: 8, color: colors.grey[500], }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    component="div"
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    <EditLandForm rowData={row.original} />
                </DialogContent>
            </>
        ),
        // Used to render detail view
        renderCreateRowDialogContent: ({ table, row }) => (
            <Box sx={{ width: "100%" }}>
                <DialogTitle variant="h4">{row.original.barcode} Asset Details</DialogTitle>
                <IconButton aria-label="close" onClick={() => table.setCreatingRow(null)} sx={{ position: 'absolute', right: 8, top: 8, color: colors.grey[500], }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0rem' }}
                >
                    <LandDetailView rowData={row.original} />
                </DialogContent>
            </Box>
        ),
        renderRowActions: ({ table, row }) => (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                {/* <Tooltip title="Edit Specialized Land">
                    <IconButton onClick={() => {
                        table.setEditingRow(row);
                    }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip> */}
                <Tooltip title="View Land Details">
                    <IconButton onClick={() => {
                        table.setCreatingRow(row);
                    }}>
                        <VisibilityOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title="Export all CSV">
                    <IconButton onClick={handleExportData}>
                        <DownloadOutlinedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title="Export Page (csv)">
                    <IconButton onClick={() => handleExportRows(table.getRowLand().rows)}>
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title="Export Selected (csv)">
                    <span>
                        <IconButton onClick={() => handleExportRows(table.getSelectedRowLand().rows)} disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }>
                            <DownloadingOutlinedIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                {/* <Tooltip arrow title="Export all PDF">
                    <IconButton onClick={() =>
                        handlePdfExport(table.getPrePaginationRowModel().rows)
                    }>
                        <PictureAsPdfOutlinedIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        ),
    });

    return (
        <>
            <Box
                m="0.1rem 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiTableCell-body": {
                        fontSize: '1.0rem',
                        fontWeight: '350',
                    },
                    "& .Mui-TableHeadCell-Content": {
                        fontSize: '1.3rem',
                        fontWeight: '400',
                        fontVariant: 'small-caps',
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.white} !important`,
                    },
                }}
            >
                <Stack direction="row" spacing={1} sx={{ mb: 1, width: '100%' }}>
                    <Box>
                        <PopupDialog
                            title="Add Land"
                            icon={<PostAddOutlinedIcon sx={{ mr: "1rem" }} />}
                        >
                            <AddLandForm />
                        </PopupDialog>
                    </Box>
                </Stack>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <MaterialReactTable table={table} />
                )}
            </Box>
        </>
    );
};

export default LandPage;